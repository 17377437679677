import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import {
  faBriefcaseMedical,
  faHandFist,
  faShield,
  faShuffle,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Alert, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesTeams: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Building (Beginner)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_team.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Team Building (Beginner)</h1>
          <h2>
            How to build teams in AFK Journey and what are the best teams you
            can run early in the game.
          </h2>
          <p>
            Last updated: <strong>18/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <StaticImage
          src="../../../images/afk/generic/team_1.webp"
          alt="Guides"
        />
        <p>
          In this guide, we offer information about the basics of team building
          and examples of pre-made teams.{' '}
          <strong>
            Please note that these are just guidelines, and not hard rules
          </strong>
          . Due to the existence of several key factors — that we will go over
          in a minute — that somewhat restrict the ways teams can be built in
          AFK Journey. If you don’t see your preferred character in this guide,
          it doesn’t mean they’re not viable; in that case just refer to our
          Tier List or character page to see what place they have in teams.
        </p>
        <p>
          If you're looking for late game or PVP teams, check our other guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building (advanced)"
            link="/afk-journey/guides/team-building-advanced"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_meta.jpg"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Faction Bonus" />
        <StaticImage
          src="../../../images/afk/generic/faction_bonus.webp"
          alt="Guides"
        />
        <p>
          While creating a team, it is important to maximize your faction bonus.
          Whenever more than 2 characters of the same faction are put on a team,
          the entire team’s stats are increased by a certain percent. This means
          you’re incentivized to keep your teams to two factions at most, though
          the <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
          factions are here to help mitigate the issue.
        </p>
        <p>
          <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" /> are
          interesting, as they serve as ‘neutral’ characters that can count
          towards any faction. To make it easier to understand: if you run 4
          characters of the <AFKItem name="Wilders" /> faction, then add either
          a Celestial or Hypogean character, it’ll activate the 5 character
          matching faction bonus.
        </p>
        <p>
          <strong>
            Note that it’s not worth it to go for a mono-faction set-up
          </strong>
          . Not only do most factions not have 5 characters that both are good
          and synergize with one another, the extra stats are just not worth it
          when compared to the utility of some of the meta characters. Instead,
          most teams function on a ‘core’,{' '}
          <strong>which is a set of 3 characters of the same faction</strong>{' '}
          that dictate what faction the team will run around. The other two
          units are then added based on synergy or power.
        </p>
        <SectionHeader title="Standard Team Setup" />
        <p>
          Even though there are many possible teams in this game, more often
          than not, they will follow the same style of building when deciding
          between which characters to use.
        </p>
        <ul>
          <li>
            Pick a{' '}
            <strong className="tank">
              <FontAwesomeIcon icon={faShield} width="18" /> Tank
            </strong>
            . Tanks are the key figures in a team, even if their presence
            doesn’t often decide the other characters that will make up the
            team. Tanks have an exceedingly small roster, so be careful with who
            you pick to build around the faction bonus.
          </li>
          <li>
            Pick 2x{' '}
            <strong className="dps">
              <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
            </strong>
            . At the time of writing this, there is no real reason to run just 1
            DPS. There aren't enough characters that can support that kind of
            playstyle, so most teams opt to run at least 2 DPS.
          </li>
          <li>
            Pick a{' '}
            <strong className="support">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
            </strong>{' '}
            Supports are often the backbone of a team. Though most Supports
            (depending on the character) can provide enough sustain on their
            own, sometimes their kits trade raw healing output for things like
            buffs or debuffs.
          </li>
          <li>
            Flex spot. Pick a{' '}
            <strong className="specialist">
              <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
            </strong>
            , a second{' '}
            <strong className="support">
              <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
            </strong>{' '}
            or a second{' '}
            <strong className="tank">
              <FontAwesomeIcon icon={faShield} width="18" /> Tank
            </strong>
            .
          </li>
          <ul>
            <li>
              Specialists are either characters with quirky kits, or characters
              that focus on multiple roles at the same time. A key observation
              you may have is that the “good” Specialists often provide
              something in terms of buffs, debuffs or enhancements for your
              other characters,
            </li>
            <li>
              You can also run a second Tank to split the agro, allowing the
              frontline to survive long enough for your team to use their
              Ultimates or a second Support - so you get more healing and more
              buffs.{' '}
              <strong>
                There's no one team setup to rule them all and who you choose
                for the flex spot depends a lot on the enemy formation.
              </strong>
            </li>
          </ul>
        </ul>
        <SectionHeader title="Team Suggestions" />
        <Alert variant="primary alert-red">
          <p>
            The teams below are just examples and while the{' '}
            <strong>
              Core Members of each faction are pretty set in stone
            </strong>
            , you can slot a lot of characters into the two remaining flex
            spots. So experiment with whoever you have raised on your account,
            rather than trying to force a character you don't have dupes of into
            the team.
          </p>
          <p>
            Also, the{' '}
            <strong>
              teams blow are meant only for the campaign/story progression
            </strong>{' '}
            as the Dream Realm and PVP meta is different.
          </p>
        </Alert>
        <p>
          Team compositions typically feature a{' '}
          <strong>core of 3 units of the same faction</strong> (so you can
          activate the +10% stat bonus), with the other 2 being flexed either
          between another faction or non-matching units. Below you will find
          beginner core teams for every faction - while some factions are better
          than others, we decided to review each of them to give you guys a
          choice on who you want to play (and also since we're at the mercy of
          RNG - despite the wishlist, you might get only dupes from one faction
          and you will be forced to play them).
        </p>
        <h5>
          <AFKItem name="Maulers" />
        </h5>
        <p>
          This team is focused mostly around comfortable AFK Stage progression
          as a F2P player. From all the core teams, the Mauler team is probably
          the strongest and makes progressing through the game easier.
        </p>
        <div className="team-table">
          <div className="header Mauler">
            <AFKItem name="Maulers" />
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="antandra"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="brutus"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="smokey-and-meerky"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="koko"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the core team</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="brutus" enablePopover />,
                despite being of the Warrior class, is quite potent as a Tank
                due to the passive that allows him to become invulnerable upon
                taking damage that would kill him. This buys enough time for his
                allies to stack up Energy for their Ultimates and turn the tide
                of combat in case things go wrong. Still, under higher deficits
                he falters as beside the immortality he has nothing in his kit
                to keep him alive and is often used in dual tank teams,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="antandra" enablePopover /> is
                a better tank than Brutus once you ascend her a bit, but early
                one it will be hard to get her dupes as there's a big
                competition for the Maulers wishlist spot for the A-Level
                characters,
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                is the standard Support here providing constant healing, ATK Up
                and Haste Up buffs in his aura,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> is
                often paired with Smokey as she provides a defensive layer of
                buffs that makes them work well together.
              </li>
            </ul>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" />
                Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="alsa"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="rhys"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="odie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="seth"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Alternatives
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="shakir"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the non-core spots</h6>
            <ul className="bigger-margin">
              <li>
                Pick two DPS from the list above - or even try another one that
                you have a lot dupes of,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="alsa" enablePopover /> is a
                consistent, CC-heavy damage dealer who can isolate enemies,
                viable well into late game in PVE and PVP.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="rhys" enablePopover /> is a
                AoE DPS providing constant area damage and being tricky to hit
                while <AFKCharacter mode="inline" slug="seth" enablePopover />{' '}
                is a killing machine but he requires a lot of dupes to be
                consistent since he jumps toward enemies. But once Seth gets
                going, he is hard to stop as he can selfheal,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is
                probably the best and most universal A-Level character in the
                game. His damage is insane and he gets a huge powerspike with
                his EX weapon, allowing him to execute targets below certain HP.
                You can use him in any PVE mode and in PVP with great success,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> are
                two very different characters that accomplish a similar thing:
                to provide additional utility to the team. In Shakir’s case, his
                Lupine Aura provides a series of buffs to him and allies
                standing within it. Rowan on the other hand provides additional
                burst healing and a smooth cycling of Energy provision. You can
                try using one of them instead of Koko if you don't have her
                duped or replace the secondary DPS to fully focus on making
                Cecia the carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> can
                also be used as another tank, to either replace Antandra/Brutus
                or to be used alongside them and replacing one of the Damage
                dealers.
              </li>
            </ul>
          </div>
        </div>
        <p>If you want to see this team in action, check Volkin's video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="WOd8fqg4Y6s" />
          </Col>
        </Row>
        <hr />
        <h5>
          <AFKItem name="Wilders" />
        </h5>
        <p>
          A very potent core comp. This team is focused on cycling energy
          through the use of Rowan in order to quickly get Florabelle and Granny
          Dahnie's ultimates up and start a crowd control chain with the rest of
          the team, backed by powerful healing.
        </p>
        <p>
          Just be aware that the 3 core members of the team -{' '}
          <AFKCharacter mode="inline" slug="granny-dahnie" enablePopover />{' '}
          <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
          <AFKCharacter mode="inline" slug="hewynn" enablePopover /> - are all
          S-Level characters and while you can buy dupes for all of them from
          the shops, it will still take you longer to ascend them.
        </p>
        <div className="team-table">
          <div className="header Wilder">
            <AFKItem name="Wilders" />
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="granny-dahnie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="lily-may"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="florabelle"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the core team</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                provides a powerful defensive basis here with a lot of
                self-sustain and defensive debuffing in the form of Haste
                Reduction and Taunt,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the best carry in the game currently who works in every mode and
                has no issues with helping your team to progress through AFK
                stages,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
                is a summoner who helps draw aggro while dealing good damage,
                having CC and being reasonably tanky herself,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> forms
                the defensive anchor, having one of the highest healing outputs
                of all the Supports currently in the game and an Ultimate that
                provides map-wide healing over time. She also provides
                additional Haste, further playing with the cooldown cycle and
                helping allies get off their important skills,
              </li>
              <li>
                Exclusive Weapons are amazing additions to the defensive power
                in this core. Granny transforms into a defensive wall when
                falling below 50% HP, Florabelle gains a shield for her summons
                and Hewynn becomes Unaffected by crowd control while casting her
                Ultimate.
              </li>
            </ul>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faVirus} width="18" />
                Damage Dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="arden"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="bryon"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="parisa"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="cassadee"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="lyca"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the non-core spots</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="arden" enablePopover />{' '}
                provides reliable AOE damage and synergizes with crowd control
                units such as Granny Dahnie and Florabelle to deal more damage,
                and as a bonus, scales well into late game PVE and PVP,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="bryon" enablePopover />{' '}
                provides additional damage in the form of a constant barrage of
                basic attacks, dishing out high single-target damage to quickly
                break up the opposing frontline,
              </li>

              <li>
                <AFKCharacter mode="inline" slug="parisa" enablePopover /> is
                another viable alternative, buffing Florabelle’s ASPD while
                dealing devastating damage to clustered enemies.
              </li>
              <li>
                Either <AFKCharacter mode="inline" slug="rowan" enablePopover />{' '}
                or <AFKCharacter mode="inline" slug="cassadee" enablePopover />{' '}
                is flexed into the open position here. Cassadee provides
                additional support for units like Bryon who are both Magic DPS
                and utilize a lot of basic attacks with her special buff while
                Rowan provides additional sustain and helps set up a powerful
                Ultimate cycle for units like Hewynn and Florabelle through the
                amount of Energy he provides,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lyca" enablePopover />{' '}
                provides a burst of Energy at the start of the match, shreds
                Phys.Def of all enemies, increases ASPD and her Ultimate
                provides a basic attack enhancing field that can quickly wipe
                the floor with enemy teams. Depending on the other DPS, you may
                not use the Phys.Def Shred, but it’s still nice to have.
              </li>
            </ul>
          </div>
        </div>
        <h5>
          <AFKItem name="Graveborn" />
        </h5>
        <p>
          The core Graveborn composition functions and is viable in PVE from
          Early to Late game, and only gets more potent as Exclusive Weapons are
          being unlocked over time.
        </p>
        <div className="team-table">
          <div className="header Graveborn">
            <AFKItem name="Graveborn" />
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="cecia"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" />
                Damage dealer
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="viperian"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="silvina"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the core team</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="thoran" enablePopover /> and a
                secondary frontliner provide a very sustainable defensive base
                that is hard to kill, which gives more space for characters like{' '}
                <AFKCharacter mode="inline" slug="cecia" enablePopover /> (who
                can summon an additional Tank) and{' '}
                <AFKCharacter mode="inline" slug="viperian" enablePopover />{' '}
                (who is constantly losing HP) to deal their damage. Thoran is
                the go-to in this case, given his resurrection mechanic and
                incredibly high damage ceiling,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="cecia" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="viperian" enablePopover />{' '}
                form the offensive basis for this team and round out the 3-man
                Graveborn Faction bonus. Cecia provides powerful physical
                damage, an additional frontliner in the form of her summon and a
                large AoE root that keeps enemies peeled off your backline. This
                enables Viperian, who takes the first few seconds of a fight to
                set up his snakes before continuously dealing high AoE damage,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="silvina" enablePopover /> can
                replace Viperian in battles that rely on quickly taking out a
                backline unit or orienting your teams round physical damage,
                Silvina is considered here for her quick and high damage output
                and relatively safe dive after she obtains her Exclusive Weapon
                to gain a shield.
              </li>
            </ul>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="ludovic"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="lucius"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="brutus"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="reinier"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the non-core spots</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is
                the first option of healer for a graveborn centric team,
                offering consistent and plentiful healing, as well as the best
                faction bonus, but either
                <AFKCharacter
                  mode="inline"
                  slug="hewynn"
                  enablePopover
                /> or <AFKCharacter mode="inline" slug="rowan" enablePopover />{' '}
                are potent supports here, with Rowan having powerful
                burst-healing and helping Viperian with his damage output by
                granting him Energy (since Mages are reliant on their Ultimates
                most of the time). Hewynn can also be effective here due to the
                global healing on her Ultimate,
              </li>
              <li>
                The second frontliner is added here to cover in case Thoran is
                killed and needs some time for his resurrection to kick in.
                <AFKCharacter mode="inline" slug="lucius" enablePopover /> can
                mitigate the damage Thoran takes outright, making him harder to
                kill, while{' '}
                <AFKCharacter mode="inline" slug="brutus" enablePopover />{' '}
                ensures that Thoran has enough time to regain his health due to
                Brutus going invincible when he is about to die, buying extra
                time,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> can
                also be placed in the Flex Position spot if you need extra team
                manipulation and support in the form of crowd control. With
                Reinier being a Hypogean it also opens up the 4-man Graveborn
                Faction bonus increasing your team’s overall tankiness and
                damage output,
              </li>
              <li>
                You can also double down on your Supports and simply run{' '}
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> and
                either
                <AFKCharacter
                  mode="inline"
                  slug="hewynn"
                  enablePopover
                /> or{' '}
                <AFKCharacter mode="inline" slug="ludovic" enablePopover />.
              </li>
            </ul>
          </div>
        </div>
        <h5>
          <AFKItem name="Lightbearers" />
        </h5>
        <p>
          By far the weakest faction as far as team-building power goes. Most of
          the good Lightbearer characters are used to supplement other teams
          rather than forming a core of their own. Despite this, there are
          options for a Lightbearer based team, as Lightbearers generally have a
          mix of offense and defensive capabilities, it’s just that you
          typically don’t need the amount of defensive options they provide.
        </p>
        <div className="team-table">
          <div className="header Lightbearer">
            <AFKItem name="Lightbearers" />
          </div>
          <div className="heroes">
            <div className="hero-column">
              <p className="role dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="vala"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role specialist">
                <FontAwesomeIcon icon={faVirus} width="18" />
                Specialist
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="korin"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role support">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Support
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="rowan"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the core team</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="vala" enablePopover /> (who
                should be the first rate-up character on release), is a powerful
                Assassin unit that can help pick off annoying targets like
                healers. Unlike Silvina, Vala starts in her ranged form, causing
                the frontline to draw aggro before she dashes in with her
                Ultimate to deliver high single-target damage. Even if the
                enemies turn on her,{' '}
                <AFKCharacter mode="inline" slug="korin" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="lucius" enablePopover /> allow
                Vala to tank more damage through the use of their shields,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="korin" enablePopover /> is a
                surprise pick for the Specialist role here. While he isn’t the
                greatest in the early game, his dual-role nature splitting his
                kit between high single-target damage and a potent (and
                consistent) shield on an ally helps when survivability becomes
                an important factor in progressing through AFK stages. He also
                becomes a beast in Dream Realm once you unlock his EX weapon,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> is a
                no-brainer here. Amazing burst healing, consistent Energy
                generation for most of the team, and extra Haste/Energy buffs
                for himself, he is the reason this core finds a sense of
                consistency.
              </li>
            </ul>
          </div>
          <div className="heroes two">
            <div className="hero-column">
              <p className="role tank">
                <FontAwesomeIcon icon={faShield} width="18" /> Tank
              </p>
              <div className="employees-container afk-cards">
                <AFKCharacter
                  mode="card"
                  slug="lucius"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="thoran"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="granny-dahnie"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
            <div className="hero-column">
              <p className="role flex">
                <FontAwesomeIcon icon={faShuffle} width="18" /> Flex spot
              </p>
              <div className="employees-container afk-cards no-border">
                <AFKCharacter
                  mode="card"
                  slug="cecia"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="hewynn"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="reinier"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
                <AFKCharacter
                  mode="card"
                  slug="florabelle"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="info">
            <h6>Information about the non-core spots</h6>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lucius" enablePopover />{' '}
                provides solid early game tanking and can really help make your
                frontline more sustainable with the AoE shields on his Ultimate
                and the bit of CC he has access to in his kit. Especially when
                paired with either melee units or summons a team can draw out a
                lot of value out of his abilities. Still, while Lucius is a
                great early game unit, his lack of immediate presence (and
                ultimately, guaranteed survivability) really harms him as you
                climb AFK stages. Instead, once you hit mid game, consider using{' '}
                <AFKCharacter
                  mode="inline"
                  slug="granny-dahnie"
                  enablePopover
                />{' '}
                or <AFKCharacter mode="inline" slug="thoran" enablePopover />{' '}
                instead. Though unnecessary, if you want to keep a 3-2 faction
                bonus team, try and match the Flex DPS with the Flex Tank
                factions,
              </li>
              <li>
                The Flex spot varies here, so{' '}
                <AFKCharacter mode="inline" slug="cecia" enablePopover /> is
                used for being an extremely powerful and easily accessible
                damage dealer. Her summon synergizes with both Lucius and Korin,
                providing a powerful frontline that is hard to break though, and
                her crowd control prevents enemies from spreading too far apart
                given that most of the units in this core are single-target
                focused,
              </li>
              <li>
                Another summoner we can run here is{' '}
                <AFKCharacter mode="inline" slug="florabelle" enablePopover />,
                who has the advantage of getting her summon out right when the
                battle starts, and scales better into the endgame.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> can
                also be placed in the Flex Position spot if you need extra team
                manipulation and support in the form of crowd control,
              </li>
              <li>
                You can also double down on your Supports and simply run both{' '}
                <AFKCharacter mode="inline" slug="hewynn" enablePopover /> and{' '}
                <AFKCharacter mode="inline" slug="rowan" enablePopover /> at the
                same time.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesTeams;

export const Head: React.FC = () => (
  <Seo
    title="Team Building (Beginner) | AFK Journey | Prydwen Institute"
    description="How to build teams in AFK Journey and what are the best teams you can run early in the game."
    game="afk"
  />
);
